<template>
  <Loader :loading="$apollo.loading">
    <Error :error="error">
      <div v-if="isDelightUser">A delight user has all the accesses !</div>
      <div v-else>
        <Select
          v-model="roleId"
          filterable
          :disabled="!!revokingId || granting"
          class="w-64"
        >
          <Option
            v-for="{ id, tenant } in availableSwitcherRoles"
            :value="id"
            :key="id"
          >
            {{ tenant.displayName || tenant.name }}
          </Option>
        </Select>
        <Button
          @click="grantTenantAccess"
          :loading="!!revokingId || granting"
          class="mx-2"
        >
          <fa :icon="['far', 'key']" class="mr-1" />
          Grant access
        </Button>

        <div v-for="{ id, tenant } in userSwitcherRoles" :key="id">
          <Button
            @click="revokeTenantAccess(id)"
            :loading="revokingId === id"
            class="mx-2"
          >
            <fa :icon="['far', 'ban']" class="mr-1"></fa>
            Revoke access
          </Button>
          {{ tenant.displayName || tenant.name }}
        </div>
      </div>
    </Error>
  </Loader>
</template>

<script>
import switcherRolesQuery from "./queries/switcherRoles.gql";
import grantRole from "./queries/grantRole.gql";
import revokeRole from "./queries/revokeRole.gql";
import userQuery from "./queries/user.gql";
import Loader from "@/components/Loader/Loader.vue";
import Error from "@/components/Error/Error.vue";

export default {
  components: { Loader, Error },
  data: () => ({
    error: null,
    roleId: "",
    switcherRoles: [],
    revokingId: "",
    granting: false,
    user: {}
  }),
  apollo: {
    switcherRoles: {
      query: switcherRolesQuery,
      update: ({ roles: { entries = [] } = {} }) => entries,
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(graphQLErrors || networkError);
      }
    },
    user: {
      query: userQuery,
      variables() {
        return { id: this.$route.params.userId };
      },
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(graphQLErrors || networkError);
      }
    }
  },
  computed: {
    isDelightUser: ({ user: { roles = [] } }) =>
      roles.some(({ tenant: { name } }) => name === "delight"),
    userSwitcherRoles: ({ user: { roles = [] }, userTenant }) =>
      roles.filter(
        ({ name, tenant }) => name === "switcher" && tenant.id != userTenant.id
      ),
    availableSwitcherRoles: ({ user: { roles = [] }, switcherRoles }) =>
      switcherRoles.filter(({ id }) => !roles.some(role => role.id === id)),
    userTenant: ({ user }) =>
      user?.roles?.find(
        ({ name, tenant }) => tenant.name !== "delight" && name !== "switcher"
      )?.tenant ?? {}
  },
  methods: {
    grantTenantAccess() {
      this.granting = true;

      this.$apollo
        .mutate({
          mutation: grantRole,
          variables: {
            userId: this.$route.params.userId,
            roleId: this.roleId
          },
          refetchQueries: [
            { query: userQuery, variables: { id: this.$route.params.userId } }
          ]
        })
        .then(() => (this.roleId = ""))
        .catch(
          ({ graphQLErrors, networkError }) =>
            (this.error = JSON.stringify(networkError || graphQLErrors))
        )
        .finally(() => (this.granting = false));
    },
    revokeTenantAccess(roleId) {
      this.revokingId = roleId;

      this.$apollo
        .mutate({
          mutation: revokeRole,
          variables: {
            userId: this.$route.params.userId,
            roleId: roleId
          },
          refetchQueries: [
            { query: userQuery, variables: { id: this.$route.params.userId } }
          ]
        })
        .catch(
          ({ graphQLErrors, networkError }) =>
            (this.error = JSON.stringify(networkError || graphQLErrors))
        )
        .finally(() => (this.revokingId = ""));
    }
  }
};
</script>

<style scoped></style>
